<script setup>
import { useAttrs, ref } from "vue";
const props = defineProps(["active"]);
const attrs = useAttrs();

const active = computed(() => {
  return props.active || attrs.active;
});
</script>
<template>
  <div class="v-label">
    <div :class="{ active, error: attrs.error }" class="lable-content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.v-label {
  position: absolute;
  pointer-events: none;
  top: 0;
}

.lable-content {
  font-weight: 400;
  font-size: 17px;
  border-radius: 5px;
  padding: 0 0.35rem;
  color: var(--icon);
  cursor: text;
  display: block;
  position: absolute !important;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;

  background-color: transparent;
  transform: translate3d(0px, 20px, 0);
  transition: transform 100ms ease-out, color 0.15s ease-out;
  transform-origin: left center;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  color: var(--text-sub-color);
}

.lable-content.active {
  font-size: 13px;
  line-height: 25px;
  background-color: var(--forms-label-bg);
  transform: translate3d(0px, -14px, 0);
  border: 1.5px solid var(--forms-border);
}

.lable-content.error {
  color: rgb(255, 104, 28);
}
</style>
